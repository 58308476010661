.text{
    font-size:medium;
}
.width{
    margin-right:65px;
}
.Heading{
    font-size: 65px;
    font-weight: 700;
    color: #2b4b80;
    padding-top: 15%;
    font-weight: 750;
    line-height: 1.2;
    font-family: "Poppins", sans-serif;
}
.content{
    padding-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #707b8e;
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
}

