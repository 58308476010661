.boxx{
        background-color: beige;
        box-sizing: content-box;  
        width: 1000px;
        height: 200px;
        padding: 30px;  
        border: 5px solid black;
      }
      
      .imgg{
        height:300px;
        width:600px;
      }
      
      .imgg1{
        height:300px;
        width:600px;
      }
      @media(max-width:840px){
        .imgg{
          height: 50%;
          width: 100%;
      }
        .imgg1{
            height: 100%;
            width: 100%;
        }
        .boxx{
          height: 50%;
          width: 80%;
      }
      }