@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,100,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navlinks > .active{
  color: rgb(176, 4, 229);
}
.Footer{
    background-color: black;
    -webkit-shape-outside: ellipse(150px 300px at 50% 50%);
            shape-outside: ellipse(150px 300px at 50% 50%);
    
 }
.contact_Contact__1SQjk{
    height: 980px;
}
.boxx{
        background-color: beige;
        box-sizing: content-box;  
        width: 1000px;
        height: 200px;
        padding: 30px;  
        border: 5px solid black;
      }
      
      .imgg{
        height:300px;
        width:600px;
      }
      
      .imgg1{
        height:300px;
        width:600px;
      }
      @media(max-width:840px){
        .imgg{
          height: 50%;
          width: 100%;
      }
        .imgg1{
            height: 100%;
            width: 100%;
        }
        .boxx{
          height: 50%;
          width: 80%;
      }
      }
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: rgba(241, 235, 235, 0.904) !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #d9e9ec;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 300px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}
@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: rgb(233, 230, 230);
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}
.Service_Card__30yVn{
    height: 100%;
    width: 400px;
    margin: 2% 0;
}
.Service_Img__Qyq37{
    height: 200px;
    width: 400px;
}

@media(max-width:840px){
    .Service_Card__30yVn{
        height: 100%;
        width: 350px;
        margin: 2% 0;
    }
    .Service_Img__Qyq37{
        height: 200px;
        width: 350px;
    }
}
@media(max-width:760px){
    .Service_Card__30yVn{
        height: 100%;
        width: 300px;
        margin: 2% 0;
    }
    .Service_Img__Qyq37{
        height: 200px;
        width: 300px;
    }
}
@media(max-width:1200px){
    .Service_Card__30yVn{
        height: 100%;
        width: 300px;
        margin: 2% 0;
    }
    .Service_Img__Qyq37{
        height: 200px;
        width: 300px;
    }
}
@media(max-width:990px){
    .Service_Card__30yVn{
        height: 100%;
        width: 350px;
        margin: 2% 0;
    }
    .Service_Img__Qyq37{
        height: 200px;
        width: 350px;
    }
}
*{
    margin: 0px;
    padding: 0px;
}
html,body{
    overflow-x: hidden;
}

.context {
    width: 100%;
    
    position: absolute;
    top:2vh;
    
}




.area{
    background: #A1731B;  
     
    width: 100%;
    height:130vh;
    
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
@media(max-width:480px){

    .gallery{
      height: 0%;
      width: 0%;
  }
  .area{
    background: #A1731B;  
    
    width: 100%;
    height:125vh;
    }
.context {
    width: 100%;

    position: absolute;
    
    
}
.google{
    height: 120px;
    width:180px;
    
    

    
}
.apple{
    height: 180px;
    width:130px;
    
}


.Heading {
    text-align: center;
    
}
.para  { 
    padding: auto; }
    
    }


    
.text{
    font-size:medium;
}
.width{
    margin-right:65px;
}
.Heading{
    font-size: 65px;
    font-weight: 700;
    color: #2b4b80;
    padding-top: 15%;
    font-weight: 750;
    line-height: 1.2;
    font-family: "Poppins", sans-serif;
}
.content{
    padding-top: 20px;
    font-family: "Poppins", sans-serif;
    color: #707b8e;
    font-size: 16px;
    line-height: 30px;
    font-weight: normal;
}



html,body{font-family:"Lexend Giga";font-size:15px;line-height:1.5}html{overflow-x:hidden}
h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}.w3-serif{font-family:serif}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}

.w3-animate-left{position:relative;animation:animateleft 1s}@keyframes animateleft{from{left:-500px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;animation:animateright 2s}@keyframes animateright{from{right:-500px;opacity:0} to{right:0;opacity:1}}



h1,
p,
a{
  margin: 0;
  padding: 0;
  
}

h1 {
  font-size: 2.8em;
  padding: 10px 0;
  font-weight: 800;
}

p {
  font-size: 1.1em;
  font-weight: 100;
  
}

.header {
  width: 100%;
  padding:60px 0;
  text-align: center;
  background:#ffd54f;
  color: white;
}


.btn-bgstroke {
  font-size: 20px;
  display: inline-block;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 300;
  margin-top: 30px; 
}

.btn-bgstroke:hover {
  background-color: rgb(140, 189, 230);
  color: #eed152;
}
.contactus_ContactUs__39NBw{
    height: 680px;
    
}

.outset {border-style: outset;
padding: 2% 1% 4% 4%;
}
.sliderimg{
    margin-top: 10%;
    width: 100%;
    height: 450px;
    object-fit: cover
  }
  @media only screen and (max-width: 934px) {
    .carouselr {
      margin-top: -9%;
      width: 88%;
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  }
.App {
    font-family: sans-serif;
    
  }
  
  .slider-arrow {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .arrow-btn {
    top: 45%;
    z-index: 1;
  }
  .next {
    float: right;
  }

