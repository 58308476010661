@import url(https://fonts.googleapis.com/css?family=Lato:400,300,100,700,900);

h1,
p,
a{
  margin: 0;
  padding: 0;
  
}

h1 {
  font-size: 2.8em;
  padding: 10px 0;
  font-weight: 800;
}

p {
  font-size: 1.1em;
  font-weight: 100;
  
}

.header {
  width: 100%;
  padding:60px 0;
  text-align: center;
  background:#ffd54f;
  color: white;
}


.btn-bgstroke {
  font-size: 20px;
  display: inline-block;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 300;
  margin-top: 30px; 
}

.btn-bgstroke:hover {
  background-color: rgb(140, 189, 230);
  color: #eed152;
}